
body {
  margin: 0px;
  padding: 0px;
}

ul.resp-tabs-list,
p {
  margin: 0px;
  padding: 0px;
}

.resp-tabs-list li {
    display: inline-block;
    padding: 12px 20px;
    margin: 0;
    list-style: none;
    cursor: pointer;
    float: left;
    color: #2A2B2F;
    width: 25%;
    text-align: center;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.resp-tabs-list li {
}
.resp-tabs-container {
  padding: 0px;
  clear: left;
}
ul.resp-tabs-list li i {
    display: block;
    font-size: 2em;
    color: #000;
    margin-bottom: 0.3em;
}
h2.resp-accordion {
  cursor: pointer;
  padding: 5px;
  display: none;
}

.resp-tab-content {
  display: none;
}
.resp-tab-active {
  border-bottom: 4px solid #EBC305;
  margin-bottom: -1px !important;
  position:relative;
  background:#EBC305;
}
.resp-tab-active:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid #EBC305;
    border-left: 10px solid rgba(69, 42, 21, 0);
    border-right: 10px solid rgba(199, 57, 57, 0);
    left: 45%;
    top: 103%;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -o-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
}

.resp-tab-active {
     background-color:#EBC305;
}

.resp-content-active,
.resp-accordion-active { display: block; }

h2.resp-accordion {
    font-size: 17px;
    border: 1px solid #c1c1c1;
    border-top: 0px solid #c1c1c1;
    margin: 0px;
    padding: 16px 15px;
    letter-spacing: 1px;
}

h2.resp-tab-active {
  border-bottom: 0px solid #fff !important;
  margin-bottom: 0px !important;
  padding: 10px 15px !important;
}

h2.resp-tab-title:last-child {
  border-bottom: 12px solid #c1c1c1 !important;
  background: blue;
}

/*-----------Vertical tabs-----------*/

.resp-vtabs ul.resp-tabs-list {
  float: left;
  width: 30%;
}

.resp-vtabs .resp-tabs-list li {
  display: block;
  padding: 15px 15px !important;
  margin: 0;
  cursor: pointer;
  float: none;
}

.resp-vtabs .resp-tabs-container {
  padding: 0px;
  background-color: #fff;
  border: 1px solid #c1c1c1;
  float: left;
  width: 68%;
  border-radius: 4px;
  clear: none;
}

.resp-vtabs .resp-tab-content { border: none; }

.resp-vtabs li.resp-tab-active {
  border: 1px solid #c1c1c1;
  border-right: none;
  background-color: #fff;
  position: relative;
  z-index: 1;
  margin-right: -1px !important;
  padding: 14px 15px 15px 14px !important;
}

.resp-arrow {
  width: 0;
  height: 0;
  float: right;
  margin-top: 3px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 12px solid #1c2020;
}

h2.resp-tab-active span.resp-arrow {
    border: none;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 12px solid #ffffff;
}

/*-----------Accordion styles-----------*/

h2.resp-tab-active { background: #ffffff !important; }

.resp-easy-accordion h2.resp-accordion { display: block; }

.resp-easy-accordion .resp-tab-content { border: 1px solid #c1c1c1; }

.resp-easy-accordion .resp-tab-content:last-child { border-bottom: 1px solid #c1c1c1 !important; }

.resp-jfit {
  width: 100%;
  margin: 0px;
}

.resp-tab-content-active { display: block; }

h2.resp-accordion:first-child { border-top: 1px solid #c1c1c1 !important; }

/*Here your can change the breakpoint to set the accordion, when screen resolution changed*/

@media(max-width:991px){
	.resp-tabs-list li {
		display: inline-block;
		width: 25%;
		font-size: 1em;
	}
}
@media only screen and (max-width: 768px) {

ul.resp-tabs-list { display: none; }

h2.resp-accordion { display: block; }

.resp-vtabs .resp-tab-content { border: 1px solid #C1C1C1; }

.resp-vtabs .resp-tabs-container {
  border: none;
  float: none;
  width: 100%;
  min-height: initial;
  clear: none;
}

.resp-accordion-closed { display: none !important; }

.resp-vtabs .resp-tab-content:last-child { border-bottom: 1px solid #c1c1c1 !important; }

h2.resp-tab-active {
    background: #EBC305 !important;
}
}
@media only screen and (max-width: 568px) {
	.resp-arrow {
		margin-top: 8px;
		border-top: 9px solid #1c2020;
	}
	h2.resp-tab-active span.resp-arrow {
		border-bottom: 9px solid #e7aa02;
	}
}
@media only screen and (max-width: 384px) {
	.resp-arrow {
		margin-top: 7px;
		border-top: 8px solid #1c2020;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
	}
	h2.resp-tab-active span.resp-arrow {
		border-bottom: 8px solid #e7aa02;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
	}
}